import React, { useState, useEffect, useCallback } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Auth } from "aws-amplify";
import debounce from "lodash.debounce";

const BasicInfoForm = ({ setIsBasicInfoFilled, navigateToTab }) => {
  const [localChannelName, setLocalChannelName] = useState(
    localStorage.getItem("localChannelName") || "",
  );
  const [localFirstName, setLocalFirstName] = useState(
    localStorage.getItem("localFirstName") || "",
  );
  const [localLastName, setLocalLastName] = useState(
    localStorage.getItem("localLastName") || "",
  );
  const [localPhoneNumber, setLocalPhoneNumber] = useState(
    localStorage.getItem("localPhoneNumber") || "",
  );
  const [channelExists, setChannelExists] = useState(null);
  const [checkingChannel, setCheckingChannel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    localStorage.setItem("localChannelName", localChannelName);
    localStorage.setItem("localFirstName", localFirstName);
    localStorage.setItem("localLastName", localLastName);
    localStorage.setItem("localPhoneNumber", localPhoneNumber);
  }, [localChannelName, localFirstName, localLastName, localPhoneNumber]);

  useEffect(() => {
    if (localChannelName) {
      checkChannelName(localChannelName);
    }
  }, [localChannelName]);

  const checkChannelName = async (channelName) => {
    if (!channelName) return false;
    setCheckingChannel(true);
    try {
      const data1 = await Auth.currentSession();
      const idToken = data1.getIdToken().getJwtToken();

      const response = await fetch(
        `https://api.kolandium.com/check_channel_name?q=${channelName}`,
        {
          headers: { Authorization: idToken },
        },
      );
      const data = await response.json();
      const exists = !data.channel_name_available;
      setChannelExists(exists);
      return exists;
    } catch (error) {
      console.error("Error checking channel name:", error);
      return false;
    } finally {
      setCheckingChannel(false);
    }
  };

  /* eslint-disable */
  const debouncedCheckChannelName = useCallback(
    debounce((value) => checkChannelName(value), 500),
    [],
  );

  const handleChannelNameChange = (e) => {
    const value = e.target.value;
    setLocalChannelName(value);
    setErrorMessage("");
    if (value) {
      debouncedCheckChannelName(value);
    } else {
      setChannelExists(null);
    }
  };

  const handleFirstNameChange = (e) => {
    setLocalFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLocalLastName(e.target.value);
  };

  const handlePhoneNumberChange = (value) => {
    setLocalPhoneNumber(value);
  };

  const isAllFieldsFilled =
    localChannelName && localFirstName && localLastName && localPhoneNumber;

  const handleSelectUploaderPassClick = async () => {
    console.log("Channel name:", localChannelName);
    console.log("First name:", localFirstName);
    console.log("Last name:", localLastName);
    console.log("Phone Number:", localPhoneNumber);
    if (!localChannelName) {
      setErrorMessage("Channel name cannot be empty.");
      setIsBasicInfoFilled(false);

      return;
    }

    if (!isAllFieldsFilled) {
      setErrorMessage("Please fill all the fields.");
      setIsBasicInfoFilled(false);
      return;
    }

    if (checkingChannel) {
      await checkChannelName(localChannelName);
    }

    if (channelExists === false) {
      setIsBasicInfoFilled(true);
      navigateToTab("Select Uploader Pass");
    } else if (channelExists === true) {
      setErrorMessage("Channel name already exists.");
      setIsBasicInfoFilled(false);
    }
  };

  const handleCancelClick = () => {
    setLocalChannelName("");
    setLocalFirstName("");
    setLocalLastName("");
    setLocalPhoneNumber("");
    setChannelExists(null);
    setErrorMessage("");
  };

  return (
    <div>
      <div className="mx-auto max-w-2xl text-center mt-8">
        <form onSubmit={(e) => e.preventDefault()}>
          <button
            className="bg-white hover:border-b-4 hover:border-green-500 hover:text-black text-black font-bold py-2 px-4 border border-black"
            type="button"
          >
            Basic Info{" "}
            {isAllFieldsFilled && <span className="text-green-500">✓</span>}
          </button>
          <div className="mb-4">
            <label htmlFor="channelName" className="block text-left mb-1">
              Channel name
              {localChannelName === "" && (
                <span className="text-red-500">*</span>
              )}
            </label>
            <input
              type="text"
              id="channelName"
              placeholder="Channel name"
              value={localChannelName}
              onChange={handleChannelNameChange}
              className="w-full h-12 border border-black shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {checkingChannel && <span>Checking...</span>}
            {localChannelName === "" &&
              errorMessage === "Channel name cannot be empty." && (
                <div className="border border-red-500 bg-red-50 p-2 mt-2 rounded text-red-500">
                  Channel name cannot be empty.
                </div>
              )}
            {channelExists === true &&
              localChannelName !== "" &&
              !errorMessage && (
                <div className="border border-red-600 bg-red-100 p-2 mt-2 rounded text-red-600">
                  Channel name already exists.
                </div>
              )}
            {channelExists === false &&
              localChannelName !== "" &&
              !errorMessage && (
                <div className="border border-green-500 bg-green-50 p-2 mt-2 rounded text-green-500">
                  Channel name is available.
                </div>
              )}
            {errorMessage &&
              errorMessage !== "Channel name cannot be empty." &&
              errorMessage !== "Channel name already exists." && (
                <div className="border border-red-500 bg-red-50 p-2 mt-2 rounded text-red-500">
                  {errorMessage}
                </div>
              )}
          </div>
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-left mb-1">
              First name
              {localFirstName === "" && <span className="text-red-500">*</span>}
            </label>
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              value={localFirstName}
              onChange={handleFirstNameChange}
              className="w-full h-12 border border-black shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-left mb-1">
              Last name
              {localLastName === "" && <span className="text-red-500">*</span>}
            </label>
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              value={localLastName}
              onChange={handleLastNameChange}
              className="w-full h-12 border border-black shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-left mb-1">
              Phone Number
              {localPhoneNumber === "" && (
                <span className="text-red-500">*</span>
              )}
            </label>
            <PhoneInput
              country="US"
              placeholder="Phone number"
              value={localPhoneNumber}
              onChange={handlePhoneNumberChange}
              className="w-full h-12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-center mb-5">
            <button
              type="button"
              className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 flex items-center"
              onClick={handleCancelClick}
            >
              Cancel
              <span className="ml-1">
                <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </button>
            <button
              type="button"
              className={`ml-5 rounded-md ${isAllFieldsFilled && channelExists !== true ? "bg-green-600" : "bg-green-300"} px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 flex items-center`}
              disabled={
                !isAllFieldsFilled || checkingChannel || channelExists === null
              }
              onClick={handleSelectUploaderPassClick}
            >
              Next
              <span className="ml-1">
                <ArrowRightIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BasicInfoForm;
