import React from "react";

const AlertCard = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded-lg text-center shadow-lg max-w-sm mx-auto">
        <h1 className="text-lg font-semibold mb-4">Channel is creating.....</h1>
        <img
          className="max-w-xs mt-4 mx-auto"
          src="./preloader-unscreen.gif"
          alt="Loading"
        />
        <h1 className="mt-4 text-green-600">
          Please don't go back or press any button
        </h1>
      </div>
    </div>
  );
};

export default AlertCard;
