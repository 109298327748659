import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { SlLike } from "react-icons/sl";
import {toast } from 'react-toastify';

const RateVideoModal = ({
  show,
  handleClose,
  handleSubmit,
  videoId,
  title,
  isLoading,
}) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchRating = async () => {
      try {
        const data = await Auth.currentSession();
        const idToken = data.getIdToken().getJwtToken();

        const response = await fetch(`https://api.kolandium.com/video_rating/${videoId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${idToken}`,
          },
        });

        if (response.ok) {
          const result = await response.json();
          setRating(result.rating);
        } else {
          console.error("Failed to fetch rating");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (show) {
      fetchRating();
    }
  }, [show, videoId]);

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(rating, videoId);
    handleClose();
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w">
        <div className="flex justify-between items-center p-2 border-b">
          <h2 className="text-lg font-semibold">Rate this Video</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </div>
        <div className="p-10">
          <form onSubmit={onSubmit}>
            <div className="mb-2">
              <Box sx={{ "& > legend": { mt: 1 } }}>
                <Typography component="legend">{title}</Typography>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
              </Box>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded mr-2"
              >
                Close
              </button>
              <button
                type="submit"
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
          
        </div>
      </div>
    </div>
  );
};

RateVideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const VideoRating = ({ videoId, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleSubmit = async (rating, videoId) => {
    setIsLoading(true);
    try {
      const data = await Auth.currentSession();
      const idToken = data.getIdToken().getJwtToken();

      const response = await fetch("https://api.kolandium.com/video_rating/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${idToken}`,
        },
        body: JSON.stringify({ video_id: videoId, rating: rating }),
      });

      const result = await response.json();
      console.log("Success:", result);
      // use toast to show success message
      toast.success('Rating submitted successfully');

    } catch (error) {
      console.error("Error:", error);
      // use toast to show error message
      toast.error('Error submitting rating, please try again');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded flex items-center"
        onClick={handleShow}
      >
        <SlLike className="mr-2.5" /> Like
      </button>

      <RateVideoModal
        show={showModal}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        videoId={videoId}
        title={title}
        isLoading={isLoading}
      />
    </div>
  );
};

VideoRating.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoRating;
