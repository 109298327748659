import MainHeader from "./MainHeader";
import PublishedVideos from "./PublishedVideos";

export default function HomeHeader() {
  return (
    <>
      <MainHeader />
      <PublishedVideos />
    </>
  );
}
