import TheTopLiveContent from "./TheTopLiveContent";
import MainHeader from "./MainHeader";

export default function TheTopLive() {
  return (
    <>
      <MainHeader />
      <div className="mt-10">
        <TheTopLiveContent />
      </div>
    </>
  );
}
