import {React, useState} from "react";
import API from "../../config/api";
import { Auth } from "aws-amplify";
import Vimeo from "@u-wave/react-vimeo";
import { FaEye } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import {
  MagnifyingGlassIcon
} from "@heroicons/react/24/outline";
import { useQuery } from "react-query";
import VideoRating from "./VideoRating";
import { Rating } from "@mui/material";

import { debounce } from "lodash";


const PublishedVideos = () => {
  const {
    data: videoList,
    isLoading,
    error,
  } = useQuery("videoList", fetchVideoList, {
    staleTime: 15 * 60 * 1000, // 15 minutes
    cacheTime: 15 * 60 * 1000, // 15 minutes
  });

  async function fetchVideoList() {
    try {
      const data = await Auth.currentSession();
      const idToken = data.getIdToken().getJwtToken();
      const res = await API.get(`/published_videos`, {
        headers: {
          Authorization: idToken,
        },
      });

      console.log(
        "VideoListApiTest.jsx: fetchVideoList() - res.data: ",
        res.data,
      );
      return res.data["video_list"];
    } catch (error) {
      throw new Error(error);
    }
  }
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = debounce(async (event) => {
    const query = event.target.value;
    if (query.length > 2) {
      const data = await Auth.currentSession();
      const idToken = data.getIdToken().getJwtToken();
      const response = await fetch(`https://api.kolandium.com/search?q=${query}`, {
        headers: {
          Authorization: idToken,
        },
      });
      const results = await response.json();
      console.log("Search results:", results.video_list);
      setSearchResults(results.video_list);
    } else {
      setSearchResults([]);
    }
  }, 600); // 600ms debounce delay

  


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-start h-screen mt-0">
        <img
          className="max-w-xs mt-4 justify-center"
          src="./preloader-unscreen.gif"
          alt="Loading"
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
          
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
            <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </div>
            <input
              id="search"
              name="search"
              type="search"
              placeholder="Search"
              className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={handleSearch}
            />
            {searchResults.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 border-line border-2 border-red-300">
                {searchResults.map((video, index) => (
                  <div
                    key={video.link}
                    className="bg-white p-4 rounded-lg shadow-md overflow-hidden flex flex-col transition-transform transform hover:scale-105"
                  >
                    <div className="flex-grow flex items-center justify-center mb-4 rounded-lg">
                      <div className="rounded-lg">
                        <Vimeo
                          video={video.link}
                          width="520"
                          height="200"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="text-xl font-semibold text-left">
                        {video.title}
                      </h3>
                      <div className="flex items-center">
                        <h1 className="font-bold mr-2">
                          uploaded by: {video.channel_name}
                        </h1>
                      </div>
                    </div>
                    <div className="flex items-center mb-4">
                      <Rating
                        name="read-only"
                        value={video.rating || 0} 
                        readOnly
                        precision={0.5}
                      />
                      &nbsp; <VideoRating videoId={video.video_id} title={video.title}></VideoRating>
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center text-sm">
                        <SlCalender className="mr-2" />
                        <p>{formatDate(video.created_time)}</p>
                      </div>
                      <div className="flex items-center text-sm">
                        <FaEye className="mr-2" />
                        <p>{video.views} views</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 overflow-hidden">
          {videoList.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {videoList.map((video, index) => (
                <div
                  key={video.link}
                  className="bg-white p-4 rounded-lg shadow-md overflow-hidden flex flex-col transition-transform transform hover:scale-105"
                >
                  <div className="flex-grow flex items-center justify-center mb-4 rounded-lg">
                    <div className="rounded-lg">
                      <Vimeo
                        video={video.link}
                        width="520"
                        height="200"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-xl font-semibold text-left">
                      {video.title}
                    </h3>
                    <div className="flex items-center">
                      <h1 className="font-bold mr-2">
                        uploaded by: {video.channel_name}
                      </h1>
                    </div>
                  </div>
                  <div className="flex items-center mb-4">
                    <Rating
                      name="read-only"
                      value={video.rating || 0} 
                      readOnly
                      precision={0.5}
                    />
                    &nbsp; <VideoRating videoId={video.video_id} title={video.title}></VideoRating>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-sm">
                      <SlCalender className="mr-2" />
                      <p>{formatDate(video.created_time)}</p>
                    </div>
                    <div className="flex items-center text-sm">
                      <FaEye className="mr-2" />
                      <p>{video.views} views</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No videos available</p>
          )}
        </div>
    </>
  );
};

export default PublishedVideos;
