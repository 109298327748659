/* eslint-disable no-unused-vars */
import { Authenticator } from "@aws-amplify/ui-react";
import { RequireAuth } from "./config/RequireAuth";
import { Login } from "./components/app/Login";
import { Home } from "./components/app/Home";
import { Layout } from "./components/app/Layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainHeader from "./components/app/MainHeader";
import BecomeUploaderHeader from "./components/app/uploader/BecomeUploaderHeader";
import HomeHeader from "./components/app/HomeHeader";
import TheTopLive from "./components/app/TheTopLive";
import UploadVideo from "./components/app/UploadVideo";
import UserHomeHeader from "./components/app/UserHomeHeader";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

function AppRoutes() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Login />} />
        </Route>

        <Route
          path="/details"
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <HomeHeader />
              </QueryClientProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/become-uploader-new"
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <BecomeUploaderHeader />
              </QueryClientProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/the-top-live"
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <TheTopLive />
              </QueryClientProvider>
            </RequireAuth>
          }
        />
        <Route
          path="/upload-video"
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <UploadVideo />
              </QueryClientProvider>
            </RequireAuth>
          }
        />

        <Route
          path="/user-profile-display"
          element={
            <RequireAuth>
              <QueryClientProvider client={queryClient}>
                <UserHomeHeader />
              </QueryClientProvider>
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <AppRoutes />    
      <ToastContainer />
    </Authenticator.Provider>
  );
}

export default App;
