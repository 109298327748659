import {React, useState, useEffect} from "react";
import Footer from "./Footer";
const calculateTimeLeft = () => {
  const difference = +new Date("2025-01-01") - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export function Home() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Kolandium - Work in progress!
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Test 2023 Thanksgiving Anim aute id magna aliqua ad ad non deserunt
          sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
          veniam occaecat fugiat aliqa.
        </p>
      </div>
      <div className="mt-6 text-lg leading-8 text-gray-600 text-center">
        <p>Time left until launch:</p>
        <div className="flex justify-center space-x-4 mt-4">
          <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">{timeLeft.days}</p>
            <p className="text-sm">Days</p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">{timeLeft.hours}</p>
            <p className="text-sm">Hours</p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">{timeLeft.minutes}</p>
            <p className="text-sm">Minutes</p>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">{timeLeft.seconds}</p>
            <p className="text-sm">Seconds</p>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-40 bottom-1">
          <Footer companyName="Your Company" year={new Date().getFullYear()} />
        </div>
      </div>
    </div>
  );
}
