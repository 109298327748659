import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import {
  XMarkIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { BsPersonVideo2 } from "react-icons/bs";
import { RiVideoUploadFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { useQuery } from "react-query";

const navigation = [
  { name: "Home", href: "/details" },
  { name: "The Top Live", href: "/the-top-live" },
];

export default function MainHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isUploader, setIsUploader] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: userProfile,
    isLoading,
    isError,
  } = useQuery("userProfile", async () => {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    const response = await fetch("https://api.kolandium.com/user_profile", {
      headers: {
        Authorization: idToken,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user profile");
    }
    return response.json();
  });

  useEffect(() => {
    if (!isLoading && !isError && userProfile) {
      const { is_uploader: userIsUploader } = userProfile;
      setIsUploader(userIsUploader);
    }
  }, [isLoading, isError, userProfile]);

  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setMobileMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logOut = () => {
    signOut();
    navigate("/login");
  };

  

  

  return (
    <>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="fixed inset-0" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex justify-between">
            <Link to="/" refresh="true" className="-m-1.5 p-1.5">
              Kolandium
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {route === "authenticated" &&
                  navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`hover:underline hover:underline-offset-4 hover:decoration-indigo-500 -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7 ${
                        location.pathname === item.href
                          ? "underline decoration-indigo-500"
                          : ""
                      }`}
                    >
                      {item.name}
                    </Link>
                  ))}
                {isUploader !== undefined && !isUploader && (
                  <Link
                    to="/become-uploader-new"
                    className="hover:underline hover:underline-offset-4 hover:decoration-indigo-500 -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7"
                  >
                    Become an Uploader
                  </Link>
                )}
                {isUploader !== undefined && isUploader && (
                  <>
                    <Link
                      to="/user-profile-display"
                      className="hover:underline hover:underline-offset-4 hover:decoration-indigo-500 -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7"
                    >
                      Profile
                    </Link>
                    <Link
                      to="/upload-video"
                      className="hover:underline hover:underline-offset-4 hover:decoration-indigo-500 -mx-3 block rounded-lg px-3 py-2 font-semibold leading-7"
                    >
                      Upload
                    </Link>
                  </>
                )}
              </div>
              <div className="py-6">
                {route !== "authenticated" ? (
                  <Link
                    to="/login"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Log in <span aria-hidden="true">&rarr;</span>
                  </Link>
                ) : (
                  <button
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={logOut}
                  >
                    Log out <span aria-hidden="true">&rarr;</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      <header className="bg-white-400 shadow-sm">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 md:px-8 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <b>Kolandium</b>
            </Link>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {route === "authenticated" &&
              navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="relative text-sm font-semibold leading-6 text-gray-900 transition-transform duration-200 hover:scale-110 hover:underline hover:underline-offset-4 hover:decoration-2 hover:decoration-indigo-500"
                >
                  {item.name}
                </Link>
              ))}
          </div>
         

          {isUploader ? (
            <Link
              to="/user-profile-display"
              className="transition-transform duration-150 relative hidden lg:inline-block items-center gap-x-1.5 rounded-md bg-amber-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
              aria-label="Profile"
            >
              <CgProfile className="inline-block h-5 w-5 rounded-full" />{" "}
              Profile
            </Link>
          ) : (
            <button
              type="button"
              className={`transition-transform duration-150 relative hidden lg:inline-block items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 ${
                isUploader ? "cursor-not-allowed opacity-50" : ""
              }`}
              aria-label="Become an Uploader"
              disabled={isUploader}
            >
              <Link
                to="/become-uploader-new"
                className="flex items-center space-x-2"
              >
                <span>Become an Uploader</span>
                <BsPersonVideo2 className="text-xl" />
              </Link>
            </button>
          )}

          <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
            {isUploader !== undefined && isUploader && (
              <button
                type="button"
                className="hover:scale-120 transition duration-50 ml-6 rounded-md bg-red-600 h-9 px-2.5 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 hidden sm:inline-block"
              >
                <Link to="/upload-video" className="flex items-center">
                  <span className="mr-1">Upload</span>
                  <RiVideoUploadFill />
                </Link>
              </button>
            )}
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {route !== "authenticated" ? (
              <Link
                to="/login"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Log in <span aria-hidden="true">&rarr;</span>
              </Link>
            ) : (
              <button
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={logOut}
              >
                Log out <span aria-hidden="true">&rarr;</span>
              </button>
            )}
          </div>
        </nav>
      </header>
      <Outlet />
    </>
  );
}
