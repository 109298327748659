import React from "react";
import { Auth } from "aws-amplify";
import { PhoneIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import { useQuery } from "react-query";
import Preloader from "./Preloader";
const UserProfileDisplay: React.FC = () => {
  const {
    data: userProfile,
    isLoading,
    isError,
  } = useQuery("userProfile", async () => {
    const data = await Auth.currentSession();
    const idToken = data.getIdToken().getJwtToken();
    console.log("idToken:", idToken);
    try {
      const response = await fetch("https://api.kolandium.com/user_profile", {
        headers: {
          Authorization: idToken,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
      return response.json();
    } catch (error) {
      throw new Error("Error fetching user profile: ");
    }
  });

  const error = isError ? "Error fetching user profile" : null;

  if (isLoading)
    return (
      <div>
        <Preloader />
      </div>
    );
  if (error) return <div>{error}</div>;
  if (!userProfile) return <div>No user profile data available.</div>;

  return (
    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        User Profile
      </h1>
      <div className="mt-4">
        <table className="min-w-full divide-y divide-gray-200">
          <tbody className="bg-white">
            <tr className="flex flex-col sm:table-row">
              <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                User ID
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                {userProfile.user_id}
              </td>
            </tr>
            <tr className="flex flex-col sm:table-row">
              <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                Email
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                <a
                  href={`mailto:${userProfile.email}`}
                  className="hover:underline"
                >
                  {userProfile.email}
                </a>
              </td>
            </tr>
            {userProfile.email_verified && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  Email Verified
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="-mr-0.5 h-5 w-5"
                    />{" "}
                    Verified
                  </span>
                </td>
              </tr>
            )}
            {userProfile.is_uploader && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  Uploader
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="-mr-0.5 h-5 w-5"
                    />{" "}
                    True
                  </span>
                </td>
              </tr>
            )}
            {userProfile.channel_name && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  Channel Name
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  <button
                    type="button"
                    className="rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-200"
                  >
                    {userProfile.channel_name}
                  </button>
                </td>
              </tr>
            )}
            {userProfile.first_name && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  First Name
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  {userProfile.first_name}
                </td>
              </tr>
            )}
            {userProfile.last_name && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  Last Name
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  {userProfile.last_name}
                </td>
              </tr>
            )}
            <tr className="flex flex-col sm:table-row">
              <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                Remaining Videos
              </td>
              <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                {userProfile.remaining_videos}
              </td>
            </tr>
            {userProfile.phone_number && (
              <tr className="flex flex-col sm:table-row">
                <td className="px-6 py-4 text-sm font-semibold text-gray-900 sm:whitespace-nowrap">
                  Phone Number
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 sm:whitespace-nowrap">
                  <span className="inline-flex items-center">
                    <PhoneIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                    <span className="ml-2">{userProfile.phone_number}</span>
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {userProfile.videos && userProfile.videos.length > 0 && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Uploaded Videos
          </h2>
          <ul className="mt-4 space-y-4">
            {userProfile.videos.map(
              (video: {
                videoId: string;
                title: string;
                url: string;
                status: string;
              }) => (
                <li key={video.videoId} className="flex flex-col space-y-2 p-4 border rounded-md shadow-sm">
                  <div className="text-lg font-semibold text-gray-900">{video.title}</div>
                  <div className="text-sm text-gray-500">ID: {video.videoId}</div>
                  <div className={`text-sm font-semibold ${video.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>
                  Status: {video.status === 'available' ? 'Published' : 'Unavailable'}
                    {video.status !== 'available' && ( 
                    <div className="text-sm text-gray-500">
                      If your video status remains unavailable after 24 hours, please email us at <a href="mailto:kolandium@outlook.com" className="text-blue-600 hover:underline">kolandium@outlook.com</a> with your video ID. We apologize for the inconvenience and will address the issue promptly.
                      </div>
                    )}
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserProfileDisplay;
